<template>
  <header>
    <div class="base-box">
      <div
        id="myImage"
        ref="myImage"
        class="image-box"
        :style="isLogin() ? 'clip-path: polygon(0 0, 100% 0%, 100% 95%, 0% 100%);' : ''"
      >
        <!-- Logo -->
        <img src="/NiuNiu-White.svg" alt="Niu-Niu" class="image-box__logo" />

        <!-- 标题 -->
        <transition name="fade-reverse">
          <div v-if="!showLoginBox" class="heading-box">
            <h1 class="heading-box__primary">
              <span class="heading-box__primary--main">Niu-Niu</span>
              <span class="heading-box__primary--sub">十八层牛肉巨无霸</span>
            </h1>

            <div class="heading-box__use">
              <a href="/guigui" target="_blank" class="mo-btn mo-btn--white mo-btn--animation-1"> 🐢 个人文档 </a>
              <a href="/swagger" target="_blank" class="mo-btn mo-btn--white mo-btn--animation-2"> 🥔 个人接口 </a>
              <a
                v-if="!isLogin()"
                href="#"
                class="mo-btn mo-btn--white mo-btn--animation-3"
                @click.prevent="
                  showLoginBox = !showLoginBox;
                  showFooter = false;
                "
              >
                👨‍💻 用户登录
              </a>
              <a
                v-if="!isLogin()"
                href="#"
                class="mo-btn mo-btn--white mo-btn--animation-4"
                @click.prevent="
                  user.email = 'test@163.com';
                  user.password = '123456';
                  showLoginBox = !showLoginBox;
                  showFooter = false;
                "
              >
                👤 游客登录
              </a>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </header>

  <main>
    <!-- 导航 -->
    <MoNavigation></MoNavigation>
    <!-- 登录弹窗 -->
    <MoLogin v-show="showLoginBox" @mousedown.self="showLoginBox = false" :user="user"></MoLogin>
    <!-- 内容 -->
    <MoDomain v-if="isLogin()" ref="myDomain"></MoDomain>
  </main>

  <footer>
    <!-- 页脚 -->
    <div ref="myFooter" v-show="showFooter && !showLoginBox">
      <MoFooter v-show="showFooter && !showLoginBox"></MoFooter>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { ref, onMounted, inject, reactive, onUnmounted } from 'vue';
import { gsap_gradient_change, gsap_enter_up, gsap_leave_down } from '@utils/gsap';
import type { AxiosInstance } from 'axios';
import { isLogin, isMobile, getPageHeight } from '@src/utils/base';
import { useThrottleFn, useWindowScroll } from '@vueuse/core';
import MoFooter from './mo-footer.vue';
import MoNavigation from './mo-navigation.vue';
import MoDomain from './mo-domain.vue';

const axios = inject<AxiosInstance>('axios');

/** dom 元素 -- 背景图像 */
const myImage = ref<HTMLDivElement | null>(null);
/** dom 元素 -- 内容区域 */
const myDomain = ref<HTMLDivElement | null>(null);

/** 状态控制 -- 是否显示登录窗口 */
const showLoginBox = ref(false);

/** dom 元素 -- 页脚 */
const myFooter = ref<HTMLDivElement | null>(null);

/** 状态控制 -- 是否页脚 */
const showFooter = ref(false);

/** 定时器 -- 触发显示页脚的判断 */
var wheelTimer = null;
/** 定时器 -- 触发显示页脚的频率 */
var wheelFrequency = 0;

/** 用户登录所需信息 */
const user = reactive({
  email: '',
  password: '',
});

/** 记录开始触摸的位置 */
var touchStartY = 0;

onMounted(() => {
  // 背景图像加载
  axios.get('/images/GetRDBingWallpaper?idx=0&n=10').then((res) => {
    if (myImage.value) {
      const imgurl = res.data;

      myImage.value.style.backgroundImage = `linear-gradient(to right bottom, rgba(119, 119, 119) , rgba(119, 119, 119) ),url(${imgurl})`;

      const img = new Image();
      img.src = imgurl;
      img.onload = function () {
        // 背景图片加载成功后 背景图片渐变
        gsap_gradient_change(myImage.value, imgurl);
      };
    }
  });

  // 监听滚动事件 - 判断是否显示页脚
  if (isMobile()) {
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove);
  } else {
    window.addEventListener('wheel', handleScroll);
  }
});

onUnmounted(() => {
  // 移除监听滚动事件 - 判断是否显示页脚
  if (isMobile) {
    window.removeEventListener('touchstart', handleTouchStart);
    window.removeEventListener('touchmove', handleTouchMove);
  } else {
    window.removeEventListener('wheel', handleScroll);
  }
});

/**
 * 监听滚动事件 - 判断是否显示页脚
 */
const handleScroll = useThrottleFn((event: WheelEvent) => {
  // 登录窗口打开时不显示页脚
  if (showLoginBox.value) {
    return;
  }

  // 记录当前滚动位置（是显示区域 top 为 0 的位置，+ window.innerHeight 才是 bottom 为 0 的位置）
  const { y } = useWindowScroll();

  // 触发页脚逻辑实现

  // 监听鼠标滚轮事件
  if (event.deltaY > 0 && !showFooter.value && y.value + window.innerHeight >= getPageHeight() - 30) {
    // 鼠标连续向下滚动三下才会触发页脚显示
    toggleFooterVisibility();
  } else if (event.deltaY < 0 && showFooter.value) {
    // 触发页脚隐藏
    toggleFooterHidden();
  }
}, 200);

const handleTouchStart = (event: TouchEvent) => {
  // 登录窗口打开时不记录
  if (showLoginBox.value) {
    return;
  }

  touchStartY = event.touches[0].clientY;
};

/**
 * 触摸结束重置触摸距离计算
 */
const handleTouchMove = useThrottleFn((event: TouchEvent) => {
  // 登录窗口打开时不显示页脚
  if (showLoginBox.value) {
    return;
  }

  // 记录当前滚动位置（是显示区域 top 为 0 的位置，+ window.innerHeight 才是 bottom 为 0 的位置）
  const { y } = useWindowScroll();

  const deltaY = touchStartY - event.touches[0].clientY;
  if (deltaY > 0 && y.value + window.innerHeight >= getPageHeight()) {
    // 向下滑动且在页面底部
    // 移动端连续向下滑动三次才触发页脚显示
    toggleFooterVisibility();
  }
  if (deltaY < 0 && showFooter.value) {
    // 向上滑动时隐藏页脚
    toggleFooterHidden();
  }
}, 200);

/** 显示页脚实际实现 */
const toggleFooterVisibility = () => {
  if (wheelFrequency === 0) {
    wheelFrequency = 1;
    clearTimeout(wheelTimer);
    wheelTimer = setTimeout(() => {
      wheelFrequency = 0;
    }, 1000);
  } else if (wheelFrequency >= 2 && !showFooter.value) {
    // 触发页脚显示
    gsap_enter_up(myFooter.value, showFooter);
    wheelFrequency = 0;
    clearTimeout(wheelTimer);
  } else {
    wheelFrequency++;
    clearTimeout(wheelTimer);
    wheelTimer = setTimeout(() => {
      wheelFrequency = 0;
    }, 1000);
  }
};

/** 隐藏页脚实际实现 */
const toggleFooterHidden = () => {
  gsap_leave_down(myFooter.value, showFooter);
  clearTimeout(wheelTimer);
  wheelFrequency = 0;
};
</script>

<style scoped lang="scss">
@use '@sass/pages/main';
@import url('@assets/niuniu/fonts/MyIconFont/iconfont.css');
</style>
