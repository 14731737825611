<template>
  <div class="mo-footer">
    <br />
    <p>Copyright © 2024 - 4202 MoyuWcc 保留所有权利</p>
    <p>如有流出 纯属故意</p>
    <p>联系邮箱：moyuwcc@163.com</p>
    <br />
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
@use '@sass/pages/footer';
</style>
