<template>
  <div class="mo-domain">
    <div class="mo-domain__container">
      <div class="mo-domain__item hidden" v-for="(item, index) in loadedFeatures" :key="'mo-domain__item--' + index">
        <img
          v-lazy="{ src: item.imageUrl, lifecycle: lazyOptions.lifecycle }"
          alt="简单明了的图片或者一只猫"
          style="width: 100%"
        />
        <!-- 标题 -->
        <div class="mo-domain__item--title">
          <h2>{{ item.title }}</h2>
        </div>
        <!-- 介绍 -->
        <div class="mo-domain__item--description">
          {{ item.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AxiosInstance } from 'axios';
import { inject, onMounted, Reactive, reactive } from 'vue';
import { useStore } from '@store/index';
import type { Features } from '@store/index';
import { useDebounceFn } from '@vueuse/core';

/** Pinia 仓库实例 */
const store = useStore();

/** axio 实例 */
const axios = inject<AxiosInstance>('axios');

/** 当前已加载功能 */
const loadedFeatures: Reactive<Features[]> = reactive([]);
/** 已加载的图片数量（无论成功还是失败） */
const loadedImages: Reactive<HTMLImageElement[]> = reactive([]);

/** 瀑布流布局配置 */
const LAYOUT_CONFIG = {
  /** 默认列数 */
  DEFAULT_COLUMNS: 3,
  /** 平板端断点 */
  TABLET_BREAKPOINT: 780,
  /** 移动端断点 */
  MOBILE_BREAKPOINT: 480,
  /** 默认间距 */
  DEFAULT_GAP: 40,
  /** 平板端间距 */
  TABLET_GAP: 30,
  /** 移动端间距 */
  MOBILE_GAP: 20,
  /** 基础顶部间距 适配首页图片 */
  BASE_TOP_SPACING: 80,
};

/** 懒加载监听 */
const lazyOptions = reactive({
  lifecycle: {
    loading: () => {},
    error: (el: HTMLImageElement) => {
      loadedImages.push(el);
    },
    loaded: (el: HTMLImageElement) => {
      loadedImages.push(el);
      adjustMasonryLayout(el);
    },
  },
});

/**
 * 获取当前布局配置
 * @returns {{ columns: number, gap: number }} 列数和间距配置
 */
const getLayoutConfig = (): { columns: number; gap: number } => {
  const width = window.innerWidth;
  // 判断是否为移动端
  if (width <= LAYOUT_CONFIG.MOBILE_BREAKPOINT) {
    return { columns: 1, gap: LAYOUT_CONFIG.MOBILE_GAP };
  }
  // 判断是否为平板端
  if (width <= LAYOUT_CONFIG.TABLET_BREAKPOINT) {
    return { columns: 2, gap: LAYOUT_CONFIG.TABLET_GAP };
  }
  return { columns: LAYOUT_CONFIG.DEFAULT_COLUMNS, gap: LAYOUT_CONFIG.DEFAULT_GAP };
};

/**
 * 初始化列高度数组
 * @param {number} columns 列数
 * @param {number} gap 间距
 * @returns {number[]} 列高度数组
 */
const initializeColumnHeights = (columns: number, gap: number): number[] => {
  const heights = Array(columns).fill(gap);
  // 设置每列的初始高度，包含顶部间距
  return heights.map(() => gap + LAYOUT_CONFIG.BASE_TOP_SPACING);
};

/**
 * 瀑布流布局实现
 */
const adjustMasonryLayout = useDebounceFn((el?: HTMLImageElement) => {
  const container = document.querySelector('.mo-domain__container') as HTMLElement;
  if (!container) {
    console.error('瀑布流布局错误，无法获取瀑布流容器元素');
    return;
  }

  // 获取布局配置
  const { columns, gap } = getLayoutConfig();
  const items = Array.from(container.children) as HTMLElement[]; // 获取已存在的卡片（mo-domain__item）

  // 设置容器最小高度防止塌陷
  container.style.minHeight = '10px';

  // 计算item宽度
  const containerWidth = container.clientWidth; // 总容器宽度
  const itemWidth = (containerWidth - gap * (columns + 1)) / columns; // 单个卡片宽度

  // 初始化列高度
  const columnHeights = initializeColumnHeights(columns, gap);
  const startX = gap;

  // 遍历项目进行布局
  items.forEach((item) => {
    // 设置统一宽度
    item.style.width = `${itemWidth}px`;

    // 找到最短的列
    const minHeight = Math.min(...columnHeights);
    const columnIndex = columnHeights.indexOf(minHeight);

    // 设置位置
    item.style.top = `${minHeight}px`;
    item.style.left = `${startX + columnIndex * (itemWidth + gap)}px`;

    // 使用 requestAnimationFrame 优化动画性能
    requestAnimationFrame(() => {
      item.classList.remove('hidden');
    });

    // 更新列高度
    columnHeights[columnIndex] += item.offsetHeight + gap;
  });

  // 设置容器最终高度
  const maxHeight = Math.max(...columnHeights);
  container.style.height = `${maxHeight}px`;
  container.style.minHeight = 'auto';

  // 控制响应式加载
  // 当已加载的图片数量等于已加载的功能数量时，开始监听可视化区域
  // 当可视化区域中存在最后一个加载完成的图片时，加载下一列功能
  if (el && loadedFeatures.length === loadedImages.length && loadedFeatures.length < store.features.length) {
    /** 可视化区域监听 */
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio > 0) {
        adjustMasonryLayout(el);
        addLoadedFeatures();
        observer.unobserve(el);
      }
    });
    observer.observe(el);
  }
}, 200);

onMounted(() => {
  // 初始化数据
  initData();
});

// 监听窗口调整
window.onresize = () => {
  adjustMasonryLayout();
};

/** 初始化功能数据 */
const initData = () => {
  // 缓存猫片用来填充没有介绍图的功能
  axios.get(`https://www.moyuwcc.cn/potato/images/GetCatOrDog?n=${store.features.length}&catOrDog=all`).then((res) => {
    let catImgIndex = 0;

    // 如果功能列表的图片地址为空则加载猫片
    store.features.forEach((item) => {
      if (!item.imageUrl || item.imageUrl == '') {
        item.imageUrl = res.data[catImgIndex];
        catImgIndex++;
      }
    });

    // 添加初始布局
    addLoadedFeatures();
    adjustMasonryLayout();
  });

  // 可持续性的竭泽而渔
  store.isSaveCatOrDog &&
    axios
      .get(`https://www.moyuwcc.cn/potato/images/SaveCatOrDog?n=${store.features.length}&catOrDog=all`)
      .then(() => {});
};

/** 添加功能 */
const addLoadedFeatures = () => {
  // 获取列数
  const { columns } = getLayoutConfig();
  loadedFeatures.push(...store.features.slice(loadedFeatures.length, loadedFeatures.length + columns));
};
</script>

<style scoped lang="scss">
@use '@sass/pages/domain' as *;
</style>
