/**
 * 封装常用方法
 */

import { useCookies } from '@vueuse/integrations/useCookies';

/**
 * 判断是否登录
 */
export const isLogin = () => {
  const cookies = useCookies();
  return cookies.get('userEmail') ?? false;
};

/**
 * 判断是否是移动端
 * @returns boolean 是否是移动端
 */
export const isMobile = () => {
  const ua = navigator.userAgent;
  return /Android|webOS|iPhone|iPod|BlackBerry/i.test(ua);
};

/**
 * vh转像素
 * @param vh 高度
 * @returns 像素
 */
export const vh2px = (vh: number) => {
  const h = document.documentElement.clientHeight;
  return (vh * h) / 100;
};

/**
 * 获取页面高度
 * @returns 页面高度
 */
export const getPageHeight = () => {
  return document.documentElement.scrollHeight;
};

/**
 * 存储 localStorage
 * @param key 键名
 * @param value 值
 */
export const setStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

/**
 * 获取 localStorage
 * @param key 键名
 * @returns 查询结果
 */
export const getStorage = (key: string) => {
  return JSON.parse(localStorage.getItem(key) ?? null);
};
