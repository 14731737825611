import { createApp } from 'vue';
import './style.scss';
import App from './App.vue';
import axiosInstance from './utils/axios';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'; // Pinia 持久化插件
// import 'element-plus/dist/index.css';    // element-plus 样式已经通过 cdn 引入

import VueLazyLoad from 'vue3-lazyload'; // 图片懒加载

const pinia = createPinia(); // Pinia
pinia.use(piniaPluginPersistedstate); // Pinia 持久化插件

const app = createApp(App);

// 配置中文会增加不必要的首屏加载负担
// 相当于全局加载，单独引入就没有必要了
// 所以在需要的时候配置中文，而不是全局配置

// 配置 element-plus 支持中文
// app.use(ElementPlus, {
//   locale: zhCn,
// });

// 全局提供 axios 实例
// app.config.globalProperties.$axios = axiosInstance;

// 依赖注入提供 axios 实例。 组件中 const axios = inject<AxiosInstance>('axios') 获取实例
app.provide('axios', axiosInstance);
app.use(pinia); // Pinia

app.use(VueLazyLoad, {
  loading: '/Niu.webp',
  error: '/Niu.webp',
});

app.mount('#app');
