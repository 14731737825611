import { defineStore } from 'pinia';
import featuresDatas from './datas/features.json';

/**
 * Pinia 仓库
 * 组件中 const store = useStore(); 使用
 * 直接 store.[...] 获取数据，Pinia 默认全部是响应式
 */
export const useStore = defineStore('mainStore', {
  state: () => ({
    /** 主页功能列表 */
    features: featuresDatas as Features[],
    /** 猫猫密钥 */
    catKey: 'live_AyyxjQEBGlfPacWzf5HQzvxXjkwXdbLFEhtIvy2YZzWubJleokH3OD7CxmJAJ3E6',

    /** 是否对猫猫和狗狗图片进行可持续性的竭泽而渔 */
    isSaveCatOrDog: true,
  }),
  getters: {},
  actions: {},
  persist: {
    pick: [], // 将需要持久化存储的数据放到里面
  },
});

/** 主页功能列表类型 */
export interface Features {
  title: string;
  description: string;
  route: string;
  imageUrl: string | null;
}
