import gsap from 'gsap';
// import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { Ref } from 'vue';
// gsap.registerPlugin(ScrollToPlugin);

/**
 * gsap 动画效果 - 晃动
 */
export const gsap_shake = (dom: string | HTMLElement) => {
  gsap.to(dom, {
    duration: 0.1,
    x: 20,
    yoyo: true,
    repeat: 3,
    ease: 'power1.inOut',
    onComplete: () => gsap.set(dom, { x: 0 }),
  });
};

/**
 * gsap 动画效果 - 背景图片加载渐变
 */
export const gsap_gradient_change = (dom: string | HTMLElement, imgurl: string) => {
  gsap.to(dom, {
    backgroundImage: `linear-gradient(to right bottom, rgba(204, 204, 204, 0.8), rgba(119, 119, 119, 0.6)), url(${imgurl})`,
    duration: 1,
  });
};

/**
 * gsap 动画效果 - 进入动画
 * 主页页脚用
 */
export const gsap_enter_up = (dom: Element, showControl?: Ref) => {
  if (!showControl?.value) {
    showControl.value = true;
  }
  gsap.fromTo(
    dom,
    {
      height: 0,
      duration: 0.5,
    },
    {
      height: 'auto',
      duration: 0.5,
    },
  );
};

/**
 * gsap 动画效果 - 离开动画
 * 主页页脚用
 */
export const gsap_leave_down = (dom: Element, showControl?: Ref) => {
  gsap.to(dom, {
    height: 0,
    duration: 0.5,
    onComplete: () => {
      if (showControl?.value) {
        showControl.value = false;
      }
    },
  });
};

/**
 * gsap 动画效果 - 进入动画
 * 登录页邮箱验证码进入用
 */
export const gsap_enter_up__login = (dom: Element, showControl?: Ref, controlValue?: any) => {
  if (showControl?.value) {
    controlValue ? (showControl.value = controlValue) : (showControl.value = true);
  }
  gsap.fromTo(
    dom,
    {
      height: 0,
      padding: '1rem 10%',
      duration: 0.3,
    },
    {
      height: 'auto',
      padding: '2rem 10%',
      duration: 0.3,
    },
  );
};

/**
 * gsap 动画效果 - 离开动画
 * 登录页邮箱验证码离开用
 */
export const gsap_leave_down__login = (dom: Element, showControl?: Ref, controlValue?: any) => {
  gsap.to(dom, {
    height: 0,
    padding: '0 10%',
    duration: 0.3,
    onComplete: () => {
      if (showControl?.value) {
        controlValue ? (showControl.value = controlValue) : (showControl.value = false);
      }
    },
  });
};
