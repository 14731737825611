<!--  
  登录组件
  自带渐显动画和透明度，如果引用组件，外部 dom 需要隐藏，需要添加 <transition name="fade-reverse"> 
  必须传递 user 对象，包含 email 和 password 属性
  user 对象必须是 reactive 对象用来保证与父组件数据同步
  -->
<template>
  <transition name="fade">
    <!-- 弹出窗口 - 登录窗口 -->
    <div class="pop-box">
      <div class="pop-box__login">
        <!-- 标题 -->
        <h2 class="pop-box__header">
          <span class="">Niu-Niu</span>
        </h2>

        <!-- 登录表单 -->
        <form @submit.prevent="" class="mo-form" novalidate>
          <!-- 邮箱 -->
          <div class="mo-form__group">
            <input
              type="email"
              @input="validateInput('email')"
              name="user_email_input"
              id="user_email_input"
              ref="userEmailInput"
              class="mo-form__input"
              placeholder="邮箱"
              maxlength="255"
              required
              v-model="user.email"
            />
            <div class="mo-form__label">
              <label for="user_email_input" class="mo-form__label--placeholder">邮箱</label>
              <transition name="fade">
                <label v-if="emailError && user.email" class="mo-form__label--error">
                  {{ emailError }}
                </label>
              </transition>
            </div>
          </div>

          <!-- 密码 -->
          <div class="mo-form__group">
            <!-- 显示密码 -->
            <div @click.prevent="showPassWord = !showPassWord" class="mo-form__pwd-show">
              <i v-if="showPassWord" class="myIcon Visible mo-form__pwd-show--icon-show"></i>
              <i v-else class="myIcon Notvisible mo-form__pwd-show--icon-nshow"></i>
            </div>

            <input
              :type="showPassWord ? `text` : `password`"
              @input="validateInput('password')"
              name="user_password_input"
              ref="userPasswordInput"
              id="user_password_input"
              class="mo-form__input"
              placeholder="密码"
              minlength="6"
              maxlength="20"
              required
              v-model="user.password"
            />
            <div class="mo-form__label">
              <label for="user_password_input" class="mo-form__label--placeholder">密码</label>
              <transition name="fade">
                <label v-if="passwordError && user.password" class="mo-form__label--error">
                  {{ passwordError }}
                </label>
              </transition>
            </div>
          </div>

          <!-- 邮箱验证码 -->
          <div ref="userEmailCodeInput" v-show="pageState === PAGE_STATE.REGISTER" class="mo-form__2-group">
            <span class="mo-form__label--error">{{ computedEmailCodeError }}</span>

            <div>
              <input
                type="text"
                name="email_code_input"
                ref="emailCodeInput"
                id="email_code_input"
                class="mo-form__input mo-form__input--small"
                placeholder="邮箱验证码"
                minlength="6"
                maxlength="6"
                required
                v-model="emailCode"
              />
            </div>
          </div>

          <!-- 操作布局 -->
          <div class="mo-form__2-group">
            <!-- 记住密码 | 忘记密码 -->
            <div class="mo-form__radio-group">
              <input
                @click="rememberPWD = !rememberPWD"
                type="radio"
                name="user_remember"
                ref="user_remember"
                id="user_remember"
                class="mo-form__radio-input"
                value="true"
                v-model="rememberPWD"
              />
              <label for="user_remember" class="mo-form__radio-label">
                <span class="mo-form__radio-button"></span>
                记住我
              </label>
              <label class="mo-form__radio-label"> 忘记密码 </label>
            </div>

            <!-- 登录 | 注册 -->
            <div class="mo-btn--right">
              <button
                :type="pageState === PAGE_STATE.REGISTER ? 'submit' : 'button'"
                @click="register"
                :class="pageState === PAGE_STATE.REGISTER ? 'mo-btn--primary' : 'mo-btn--border-primary'"
                class="mo-btn myIcon"
                ref="userRegisterButton"
              >
                注册
                <span v-if="pageState === PAGE_STATE.REGISTER">
                  {{ isRegisterLoading ? `` : '&#xe773;' }}
                  <i v-if="isRegisterLoading" class="myIcon mo-rotate-360">&#xe649;</i>
                </span>
              </button>
              <button
                :type="pageState === PAGE_STATE.LOGIN ? 'submit' : 'button'"
                @click="login"
                :class="pageState === PAGE_STATE.LOGIN ? 'mo-btn--primary' : 'mo-btn--border-primary'"
                class="mo-btn myIcon"
                ref="userLoginButton"
                style="margin-left: 10px"
              >
                登录
                <span v-if="pageState === PAGE_STATE.LOGIN">
                  {{ isLoginLoading ? `` : '&#xe773;' }}
                  <i v-if="isLoginLoading" class="myIcon mo-rotate-360">&#xe649;</i>
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { ref, inject, onUpdated, computed, Ref, Reactive } from 'vue';
import { gsap_shake, gsap_enter_up__login, gsap_leave_down__login } from '@utils/gsap';
import type { AxiosInstance } from 'axios';
import { ElMessage } from 'element-plus';
import { useDebounceFn } from '@vueuse/core';
import { useCookies } from '@vueuse/integrations/useCookies';

const props = withDefaults(
  defineProps<{
    /** 用户登录|注册所需信息 */
    user: Reactive<{ email: string; password: string }>;
  }>(),
  {},
);
// const emit = defineEmits<{
//   /** 向父组件传递登录成功信息 */
//   (e: 'loginScuess'): void;
// }>();
/** 依赖注入 引用 axios Http 请求库 */
const axios = inject<AxiosInstance>('axios');

// 定义响应式变量 ------------ //
/** 邮箱验证码 */
const emailCode = ref('');

// DOM 元素引用 ------------ //
/** 输入框 - 邮箱 */
const userEmailInput = ref<HTMLInputElement | null>(null);
/** 输入框 - 密码 */
const userPasswordInput = ref<HTMLInputElement | null>(null);
/** 输入框 - 邮箱验证码 */
const userEmailCodeInput = ref<HTMLInputElement | null>(null);
/** 按钮 - 登录 */
const userLoginButton = ref<HTMLButtonElement | null>(null);
/** 按钮 - 注册 */
const userRegisterButton = ref<HTMLButtonElement | null>(null);

// 状态控制变量 ------------ //

/** 状态控制 - 是否显示明文密码 */
const showPassWord = ref(false);
/** 状态控制 - 是否记住密码 */
const rememberPWD = ref(false);
/** 状态控制 - 登录加载状态 */
const isLoginLoading = ref(false);
/** 状态控制 - 注册加载状态 */
const isRegisterLoading = ref(false);
/** 状态控制 - 邮箱验证码倒计时 */
const emailCodeTime = ref(0);
/** 状态控制 - 邮箱验证码倒计时定时器 */
let emailCodeInterval: NodeJS.Timeout | null = null;

/** 页面状态常量 */
enum PAGE_STATE {
  /** 登录 */
  LOGIN = 1,
  /** 注册 */
  REGISTER = 2,
  /** 忘记密码（未实现） */
  FORGOT = 3,
}
/** 页面状态 */
const pageState = ref<PAGE_STATE>(PAGE_STATE.LOGIN);

// 错误提示信息 ------------ //
/** 邮箱错误提示 */
const emailError = ref('');
/** 密码错误提示 */
const passwordError = ref('');
/** 邮箱验证码错误提示 */
const emailCodeError = ref('');
/** 邮箱验证码过期计时提示 */
const computedEmailCodeError = computed(() => {
  if (emailCodeError.value === '验证码已发送') {
    return `验证码已发送，${emailCodeTime.value}秒后点击注册重新发送`;
  }
  return emailCodeError.value;
});

// 组件更新时验证输入
onUpdated(() => {
  validateInput('email');
  validateInput('password');
});

/** 登录逻辑 */
const login = useDebounceFn(async () => {
  handleButtonBlur(userLoginButton);
  changeEmailCodeState(PAGE_STATE.LOGIN);

  // 因为登录和注册公用一个表单，所以需要先验证输入
  validateInput('email');
  validateInput('password');

  if (handleInvalidInput('login')) return;

  isLoginLoading.value = true;
  try {
    const res = await axios.post('/auth/login', props.user);
    handleLoginSuccess(res.data.accessToken);
  } catch (err) {
    handleLoginError(err);
  } finally {
    setTimeout(() => (isLoginLoading.value = false), 1000);
  }
}, 500);

/** 注册逻辑 */
const register = useDebounceFn(async () => {
  handleButtonBlur(userRegisterButton);

  if (props.user.email === 'test@163.com') {
    ElMessage.error('游客账户不允许注册');
    return;
  }

  changeEmailCodeState(PAGE_STATE.REGISTER);

  // 因为登录和注册公用一个表单，所以需要先验证输入
  validateInput('email');
  validateInput('password');
  validateInput('emailCode');

  if (handleInvalidInput('emailCode')) return;

  if (emailCodeTime.value === 0) {
    sendEmailCode();
    return;
  }

  if (handleInvalidInput('register')) return;

  isRegisterLoading.value = true;
  try {
    await axios.post(`/users/create/${emailCode.value}`, props.user);
    ElMessage.success('注册成功');
    login();
  } catch (err) {
    emailCodeError.value = err.response.data.data.message;
    gsap_shake(userEmailCodeInput.value);
    userEmailCodeInput.value.focus();
  } finally {
    setTimeout(() => (isRegisterLoading.value = false), 1000);
  }
}, 500);

/** 发送邮箱验证码 */
const sendEmailCode = async () => {
  emailCodeError.value = '正在发送验证码';
  isRegisterLoading.value = true;
  try {
    await axios.post('/users/sendEmail', { email: props.user.email });
    emailCodeError.value = '验证码已发送';
    startEmailCodeTimer();
  } catch (err) {
    emailCodeError.value = err.response.data.data.message;
  } finally {
    setTimeout(() => (isRegisterLoading.value = false), 1000);
  }
};

/** 验证输入 静默提示 */
const validateInput = (type: 'email' | 'password' | 'emailCode') => {
  if (type === 'email') {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[a-zA-Z]+$/;
    emailError.value = props.user.email && !emailPattern.test(props.user.email) ? '请输入有效的邮箱地址' : '';
  } else if (type === 'password') {
    passwordError.value = props.user.password && props.user.password.length < 6 ? '密码长度至少为6位' : '';
  } else if (type === 'emailCode') {
    emailCodeError.value = emailCode.value.length !== 6 ? '请输入6位验证码' : '';
  }
};

/** 处理无效输入 动画效果 */
const handleInvalidInput = (type: 'login' | 'register' | 'emailCode') => {
  let hasError = false;

  // 邮箱验证码只需要邮箱正确
  if (type !== 'emailCode') {
    // 注册需要邮箱验证码正确
    if (type === 'register') {
      if (emailCode.value.length !== 6) {
        gsap_shake(userEmailCodeInput.value);
        userEmailCodeInput.value.focus();
        hasError = true;
      }
    }

    if (passwordError.value || props.user.password === '') {
      gsap_shake(userPasswordInput.value);
      userPasswordInput.value.focus();
      hasError = true;
    }
  }

  // 所有验证都需要邮箱正确
  if (emailError.value || props.user.email === '') {
    gsap_shake(userEmailInput.value);
    userEmailInput.value.focus();
    hasError = true;
  }

  return hasError;
};

/** 处理按钮失去焦点 */
const handleButtonBlur = (buttonRef: Ref<HTMLButtonElement | null>) => {
  try {
    buttonRef.value?.blur();
  } catch (error) {}
};

/** 处理登录成功 */
const handleLoginSuccess = (token: string) => {
  ElMessage.success('登录成功');
  const cookies = useCookies();
  cookies.remove('token');
  const cookieOptions = rememberPWD.value ? { maxAge: 3 * 24 * 60 * 60 } : {};
  cookies.set('token', token, cookieOptions);
  cookies.set('userEmail', props.user.email);
  location.reload();
};

/** 处理登录错误 */
const handleLoginError = (err: any) => {
  if (err.response.status === 401) {
    passwordError.value = '密码错误';
    gsap_shake(userPasswordInput.value);
    userPasswordInput.value.focus();
  } else if (err.response.status === 404) {
    emailError.value = '邮箱不存在';
    gsap_shake(userEmailInput.value);
    userEmailInput.value.focus();
  } else {
    ElMessage.warning('登录失败。错误信息：' + err.response.data.data.message);
  }
};

/** 开始邮箱验证码计时器 */
const startEmailCodeTimer = () => {
  emailCodeTime.value = 60;
  emailCodeInterval = setInterval(() => {
    if (emailCodeTime.value > 0) {
      emailCodeTime.value--;
    } else {
      emailCodeError.value = '验证码已失效，点击注册将重新发送';
      clearInterval(emailCodeInterval);
    }
  }, 1000);
};

/** 切换页面状态 */
const changeEmailCodeState = (state: PAGE_STATE) => {
  if (pageState.value === state) return;
  if (pageState.value === PAGE_STATE.LOGIN) {
    gsap_enter_up__login(userEmailCodeInput.value, pageState, PAGE_STATE.REGISTER);
  } else if (pageState.value === PAGE_STATE.REGISTER) {
    gsap_leave_down__login(userEmailCodeInput.value, pageState, PAGE_STATE.LOGIN);
  }
};
</script>

<style scoped lang="scss">
@use '@sass/pages/login';
@import url('@assets/niuniu/fonts/MyIconFont/iconfont.css');
</style>
