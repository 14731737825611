<template>
  <div class="mo-navigation">
    <input type="checkbox" @click="showNav = !showNav" class="mo-navigation__checkbox" name="navi-toggle" id="navi-toggle" />
    <label for="navi-toggle" class="mo-navigation__button">
      <span class="mo-navigation__icon">&nbsp;</span>
    </label>

    <div class="mo-navigation__background">&nbsp;</div>

    <nav v-if="showNav" class="mo-navigation__nav">
      <div class="mo-navigation__list">
        <ul class="mo-navigation__list--arrange">
          <li class="mo-navigation__item">
            <a href="https://www.baidu.com/" target="_blank" class="mo-navigation__link">
              <img class="mo-navigation__link--image" src="/icons/baidu_favicon.ico" alt="百度 Logo" />
              &nbsp;百度
            </a>
          </li>
          <li class="mo-navigation__item">
            <a href="https://www.google.com.hk/" target="_blank" class="mo-navigation__link">
              <img class="mo-navigation__link--image" src="/icons/google_favicon.ico" alt="谷歌 Logo" />
              &nbsp;谷歌
            </a>
          </li>
          <li class="mo-navigation__item">
            <a href="https://cn.bing.com/" target="_blank" class="mo-navigation__link">
              <img
                class="mo-navigation__link--image"
                src="https://cn.bing.com/sa/simg/favicon-trans-bg-blue-mg-png.png"
                alt="必应 Logo"
              />
              &nbsp;必应
            </a>
          </li>
        </ul>

        <ul class="mo-navigation__list--arrange">
          <li class="mo-navigation__item">
            <a href="https://www.moyuwcc.cn/chat/" target="_blank" class="mo-navigation__link">
              <img class="mo-navigation__link--image" src="/icons/chat_favicon.svg" alt="Chat Logo" />
              &nbsp;Chat GPT (自建)
            </a>
          </li>
          <li v-if="isLogin()" class="mo-navigation__item">
            <a href="#" target="_blank" class="mo-navigation__link">
              <img class="mo-navigation__link--image" src="/Niu.webp" alt="user Logo" />
              &nbsp;{{ isLogin() }}
            </a>
          </li>
          <li v-if="isLogin()" class="mo-navigation__item">
            <a href="#" @click="dialogVisible = true" class="mo-navigation__link"> 🫂&nbsp;退出登录 </a>
          </li>
        </ul>
      </div>
    </nav>

    <el-dialog v-model="dialogVisible" width="clamp(1px, 80vw, 500px)" top="20%" title="提示" draggable>
      <span>确定退出登录？</span>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="danger" @click="logOff"> 退出 </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
import { isLogin } from '@src/utils/base';
import { useCookies } from '@vueuse/integrations/useCookies';
import { ref } from 'vue';

const cookies = useCookies();

const showNav = ref(false);

const logOff = () => {
  cookies.remove('token');
  cookies.remove('userEmail');
  location.reload();
};

const dialogVisible = ref(false);
</script>

<style scoped lang="scss">
@use '@sass/pages/navigation';
@import url('@assets/niuniu/fonts/MyIconFont/iconfont.css');
</style>
